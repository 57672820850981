<template lang="pug">
  b-row
    b-col.mb-4.mb-xl-0(
      md='12'
      lg='12'
      xl='5')
      b-card.manager-machines-machine-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body.d-flex.justify-content-start.align-items-center
          template(v-if='panelShow')
            .panel-picture.mr-4
              b-avatar.wc-bubble.wc-bubble-md.wc-bubble-br.mr-3.shadow(
                :src="$getDeep(panel.rs, 'model.attributes.portrait_url')"
                variant='white'
                @click.native='onMachineRefresh'
                alt='')
                template(v-if="!$getDeep(panel.rs, 'model.attributes.portrait_url')" #default)
                  .wc-bubble-content.wc-bubble-linked(:class="isOnline ? 'bg-success' : 'bg-primary' ")
                    .i-wrapper
                      i.fal.fa-fw.fa-2x.fa-conveyor-belt-alt.text-white

            .panel-info.d-flex.flex-column.flex-fill
              .panel-info-serial.font-weight-bold
                | {{ panel.data.attributes.serial }}
              .panel-info-model.small.text-muted(v-if='panel.rs.model')
                | {{ $getDeep(panel.rs, 'model.attributes.name') }}
              .panel-info-data.mb-2.mt-4.mb-1
                span.panel-info-organization.small.mr-3(v-if='panel.rs.organization')
                  i.fal.fa-fw.fa-building.text-muted
                  |  {{ $getDeep(panel.rs, 'organization.attributes.name') }}

              .panel-info.d-flex.justify-content-between.flex-wrap-reverse
                .panel-info-modus.d-flex.align-items-center.mt-3
                  .panel-machine-lock.d-flex
                    i.fal.fa-fw.mt-2.mr-1.text-primary(:class='lockedIconClass')
                    b-form-checkbox#panel-machine-lock(
                      name='lock'
                      size='lg'
                      v-model='panel.data.attributes.locked'
                      :value='true'
                      :unchecked-value='false'
                      :disabled='!isOnline || switches.lock.isLoading'
                      @change='onMachineLock'
                      switch)
                //-
                  .mx-1
                  .panel-machine-arm.d-flex
                    i.fal.fa-fw.mt-2.mr-1.text-primary(:class='armedIconClass')
                    b-form-checkbox#panel-machine-arm(
                      name='arm'
                      size='lg'
                      v-model='panel.data.attributes.armed'
                      :value='true'
                      :unchecked-value='false'
                      :disabled='!isOnline || switches.arm.isLoading'
                      @change='onMachineArm'
                      switch)
                .panel-info-state.mt-3
                  b-badge.state.p-2(
                    :variant="$wc.conf.states.machine.variants[panel.data.attributes.state]"
                    pill)
                    i.fa-fw(:class="$wc.conf.states.machine.icons[panel.data.attributes.state]")
                    |  {{ $t('machine.states.' + panel.data.attributes.state) }}
          template(v-else)
            wc-loaders-heading-avatar
    b-col.mb-4.mb-xl-0(
      md='12'
      lg='12'
      xl='4')
      b-card.manager-machines-machine-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body
          template(v-if='panelShow')
            b-list-group.justify-content-start.p-0.h-100(flush)
              b-list-group-item.border-0(
                :title="$t('activerecord.models.firmware.one')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-microchip
                span
                  | {{ $getDeep(panel.rs, 'firmware.attributes.version') || $t('firmware.no_firmware_assoc') }}
              b-list-group-item.border-0(
                :title="$t('activerecord.models.machine.one')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-usb-drive
                span
                  | {{ panel.data.attributes.version || $t('firmware.no_firmware_known') }}
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.machine.online_at')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-wifi
                span
                  | {{ panel.data.attributes.online_at || $t('machine.no_info') }}
          template(v-else)
            wc-loaders-list(:lines='5')
    b-col(
      md='12'
      lg='12'
      xl='3')
      b-card.manager-machines-machine-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body
          template(v-if='panelShow')
            b-list-group.justify-content-start.p-0.h-100(flush)
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.shared.updated_at')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-calendar-check
                span
                  | {{ panel.data.attributes.updated_at }}
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.shared.created_at')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-calendar-plus
                span
                  | {{ panel.data.attributes.created_at }}
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.machine.offline_at')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-wifi-slash
                span
                  | {{ panel.data.attributes.offline_at || $t('machine.no_info') }}
          template(v-else)
            wc-loaders-list(:lines='5')
</template>

<script>
import { Panel } from '@common/panel'
import WcLoadersHeadingAvatar from '@components/shared/loaders/WcLoadersHeadingAvatar'
import WcLoadersList from '@components/shared/loaders/WcLoadersList'

export default {
  name: 'manager-machines-machine-panel',
  mixins: [Panel],
  components: {
    WcLoadersHeadingAvatar,
    WcLoadersList,
  },
  computed: {
    isOnline() {
      return !!this.panel.data.attributes.online
    },
    isLocked() {
      return this.panel.data.attributes.status == 2
    },
    isArmed() {
      return this.panel.data.attributes.status == 3
    },
    lockedIconClass() {
      return this.isLocked ? 'fa-lock' : 'fa-unlock'
    },
    armedIconClass() {
      return this.isArmed ? 'fa-bell-on' : 'fa-bell'
    },
  },
  methods: {
    channelSubscribe() {
      if (!this.$cable._channels.subscriptions['Api::V1::Machine::RpcChannel']) {
        this.$cable.subscribe({
          channel: 'Api::V1::Machine::RpcChannel',
          id: this.$route.params.machine_id,
        })
      }
      if (!this.$cable._channels.subscriptions['Api::V1::Machine::AttributesChannel']) {
        this.$cable.subscribe({
          channel: 'Api::V1::Machine::AttributesChannel',
          id: this.$route.params.machine_id,
        })
      }
    },
    onMachineRefresh() {
      if (!this.cable.rpcConnected) return
      this.$cable.perform({
        channel: 'Api::V1::Machine::RpcChannel',
        action: 'attributes_update',
      })
    },
    onMachineLock(value) {
      this.switches.lock.isLoading = true
      this.$cable.perform({
        channel: 'Api::V1::Machine::AttributesChannel',
        action: 'modus',
        data: {
          modus: value ? 2 : 1,
        },
      })
    },
    cableModusChange() {
      this.panel.data.attributes.locked = this.cable.attributes.modus == 2
      this.panel.data.attributes.armed = this.cable.attributes.modus == 3
      this.switches.lock.isLoading = false
      this.switches.arm.isLoading = false
    },
    cableOnlineChange() {
      this.panel.data.attributes.online = this.cable.attributes.online
      this.panel.data.attributes.online_at = this.cable.attributes.online_at
      this.panel.data.attributes.offline_at = this.cable.attributes.offline_at
    },
  },
  channels: {
    'Api::V1::Machine::RpcChannel': {
      connected() {
        this.cable.rpcConnected = true
      },
      rejected() {
        this.cable.rpcConnected = false
      },
      received(response) {
        this.cable.rpc = response || {}
      },
      disconnected() {
        this.cable.rpcConnected = false
      },
    },
    'Api::V1::Machine::AttributesChannel': {
      connected() {
        this.cable.attributesConnected = true
        this.$cable.perform({
          channel: 'Api::V1::Machine::AttributesChannel',
          action: 'init',
          data: {
            locale: this.$wc.shared.locale,
          },
        })
      },
      rejected() {
        this.cable.attributesConnected = false
      },
      received(response) {
        this.cable.attributes = response || {}
        if (this.cable.attributes.modus) this.cableModusChange()
        if (this.cable.attributes.status)
          this.panel.data.attributes.status = this.cable.attributes.status
        if (this.cable.attributes.version)
          this.panel.data.attributes.version = this.cable.attributes.version
        if (Object.prototype.hasOwnProperty.call(this.cable.attributes, 'online'))
          this.cableOnlineChange()
      },
      disconnected() {
        this.cable.attributesConnected = false
      },
    },
  },
  watch: {
    panel() {
      this.panel.rs.organization = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.organization.data.type'),
        this.$getDeep(this.panel.data, 'relationships.organization.data.id')
      )
      this.panel.rs.model = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.model.data.type'),
        this.$getDeep(this.panel.data, 'relationships.model.data.id')
      )
      this.panel.rs.firmware = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.firmware.data.type'),
        this.$getDeep(this.panel.data, 'relationships.firmware.data.id')
      )
    },
    panelShow() {
      this.channelSubscribe()
    },
  },
  data() {
    return {
      cacheType: 'manager/machines/getPanel',
      cachePayload: { id: this.$route.params.machine_id },
      cable: {
        rpcConnected: false,
        rpc: {},
        attributesConnected: false,
        attributes: {},
      },
      switches: {
        lock: {
          isLoading: false,
        },
        arm: {
          isLoading: false,
        },
      },
    }
  },
}
</script>
