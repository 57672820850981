<template lang="pug">
  .manager-machines-show
    b-row.mb-5
      b-col
        machines-panel(ref='machinesPanel')
    b-row.mb-5
      b-col
        router-view(@machine-changed='reloadMachinesPanel')
</template>

<script>
import MachinesPanel from '@views/container/manager/machines/Panel'

export default {
  name: 'manager-machines-show',
  components: {
    MachinesPanel,
  },
  methods: {
    reloadMachinesPanel() {
      this.$refs.machinesPanel.panelReload()
    },
  },
}
</script>
